import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import SearchModule from "../components/search2/searchModule"
import SEO from "../components/seo"
import Breadcrumb from "../components/layout/breadcrumb"
import Collapsible from "react-collapsible"

const ManufacturerDetail = ({ data, location }) => {
  const [searchFilters, setSearchFilters] = useState({
    refinementList: { manufacturerName: [data.manufacturer.name] },
    page: 1,
  })
  const [manufacturerName, setManufacturerName] = useState(
    data.manufacturer.name
  )
  const [expanderOpen, setExpanderOpen] = useState(false)

  return (
    <Layout>
      <SEO
        title={`${manufacturerName} Lab Equipment for Sale`}
        description={manufacturerName}
        keywords={manufacturerName}
      />
      <Breadcrumb
        paths={[
          { name: "Manufacturers", path: "browse-manufacturers" },
          { name: manufacturerName, path: location.pathname.slice(1) },
        ]}
      />
      <div className="row">
        <h1>Manufacturer: {manufacturerName}</h1>

        <>
          <Collapsible
            trigger={
              <div
                dangerouslySetInnerHTML={{
                  __html: data.manufacturer.description,
                }}
              />
            }
            classParentString="custom"
            triggerDisabled
            open={expanderOpen}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.manufacturer.overflowDescription,
              }}
            />
          </Collapsible>
          {data.manufacturer.overflowDescription && (
            <div style={{ textAlign: "right" }}>
              {expanderOpen ? (
                <a onClick={() => setExpanderOpen(!expanderOpen)}>show less</a>
              ) : (
                <a onClick={() => setExpanderOpen(!expanderOpen)}>show more</a>
              )}
            </div>
          )}
        </>

        <SearchModule showManufacturer={false} searchFilters={searchFilters} />
      </div>
    </Layout>
  )
}

export default ManufacturerDetail

export const query = graphql`
  query($slug: String!) {
    manufacturer(slug: { eq: $slug }) {
      name
      description
      overflowDescription
    }
  }
`
